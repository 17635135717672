import { defineStore } from 'pinia';

import {
  authKeyStorage,
  limparStorage,
  keyCompanyName,
} from '@/core/utils/auth.util';
import apiHttpService, { httpClient } from '@/core/http/api.http.service';
import { IFormResult } from '@/core/models/results.interface';
import { HubConnection } from '@microsoft/signalr';

const usuarioLogado =
  JSON.parse(localStorage.getItem(authKeyStorage) as string) || {};

const KEY_SOM_NOTIFICACAO = `${keyCompanyName}som-notificacao`;

const somNotificacao = localStorage.getItem(KEY_SOM_NOTIFICACAO);

export const useStore = defineStore('zapply', {
  state: () => {
    return {
      menus: new Array<any>(),
      hubs: {
        atendimento: null as unknown as HubConnection,
        chat: null as unknown as HubConnection,
        app: null as unknown as HubConnection,
      },
      logout: false,
      usuarioLogado: usuarioLogado,
      somNotificacao: somNotificacao === 'true',
      qtdOperadoresOnline: 0,
      operadores: new Array<string>(),
      tracks: new Array<any>(),
      qtdAtendimentos: 0,
      config: {
        somNotificacao: '6.mp3',
        timezone: 'E. South America Standard Time',
        tamanhoBucketFormatado: '',
        tamanhoMaximoBucketFormatado: '',
        tamanhoBucket: 0,
        porcentagemUsoBucket: 0,
      },
      permissao: {
        admin: false,
        owner: false,
        primeiroAcesso: false,
        credencial: {},
        menu: {},
        trial: {
          trial: false,
          expirado: false,
          dias: 0,
          inicio: '',
          final: '',
          porcentagem: 0,
        },
      },
    };
  },
  getters: {
    codUsuarioLogado: (state) => state.usuarioLogado.codigo,
    nomeUsuarioLogado: (state) => state.usuarioLogado.nome,
    codWorkspace: (state) => state.usuarioLogado.codWorkspace,
    emailUsuarioLogado: (state) => state.usuarioLogado.email,
    aliasUsuarioLogado: (state) => state.usuarioLogado.alias,
    cpfUsuarioLogado: (state) => state.usuarioLogado.cpf,
    usuarioEstalogado: (state) => !!state.usuarioLogado.token,
    token: (state) => state.usuarioLogado.token,
    logo: (state) => state.usuarioLogado.logo,
    owner: (state) => state.usuarioLogado.owner,
    statusUsuario: (state) => state.usuarioLogado.status,
    tamanhoBucketFormatado: (state) => state.config.tamanhoBucketFormatado,
    trial: (state) => state.permissao.trial,
    tamanhoMaximoBucketFormatado: (state) =>
      state.config.tamanhoMaximoBucketFormatado,
    porcentagemUsoBucket: (state) => state.config.porcentagemUsoBucket,
  },
  actions: {
    setCredencial(payload: any) {
      const obj = payload.credenciais.reduce((accumulator: any, value: any) => {
        return { ...accumulator, [value]: true };
      }, {});

      this.permissao = {
        credencial: obj,
        admin: payload.admin,
        owner: payload.owner,
        menu: payload.menu,
        primeiroAcesso: payload.primeiroAcesso,
        trial: payload.trial,
      };
    },
    setLogoUsuario(logo: string) {
      this.usuarioLogado.logo = logo;
      localStorage.setItem(authKeyStorage, JSON.stringify(this.usuarioLogado));
    },
    setStatusUsuario(codStatusUsuario: number) {
      this.usuarioLogado.status = codStatusUsuario;
      localStorage.setItem(authKeyStorage, JSON.stringify(this.usuarioLogado));
    },
    setMenus(menus: any[]) {
      this.menus = menus;
    },
    setQtdAtendimentos(qtd: number) {
      this.qtdAtendimentos = qtd;
    },
    setHub(hubName: string, hub: HubConnection) {
      this.hubs[hubName as keyof typeof this.hubs] = hub;
    },
    setOperadorOnline(result: any) {
      this.qtdOperadoresOnline = result.qtdOperadores;
      this.operadores = [...new Set(result.usuarios as string[])];
    },
    toggleSomNotifiacao() {
      this.somNotificacao = !this.somNotificacao;
      localStorage.setItem(
        KEY_SOM_NOTIFICACAO,
        JSON.stringify(this.somNotificacao)
      );
    },
    setTracks(track: any) {
      this.tracks.push(track);
    },
    setUserConfiguration(payload: any) {
      this.config = payload;
    },
    logout() {
      this.usuarioLogado = {};
      limparStorage();
    },
    login(login: any) {
      return new Promise<IFormResult<any>>((resolve, reject) => {
        apiHttpService
          .post<any>(`/authorization`, login)
          .then((result) => {
            if (result.success) {
              const authorizationKey = 'Authorization';
              httpClient.defaults.headers.common[
                authorizationKey
              ] = `bearer ${result.data.token}`;
              this.usuarioLogado = result.data;
              localStorage.setItem(authKeyStorage, JSON.stringify(result.data));
            }

            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
