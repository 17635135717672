import HttpClientService from "@/core/http/api.http.service";

class UsuarioService {
  getContainer() {
    return HttpClientService.get<any>(`/usuario/container`);
  }

  post(usuario: any,id?:string) {

    const qs = id? `/${id}` : ``
    return HttpClientService.post<any>(`/usuario${qs}`, usuario);
  }

  uploadLogo(files: any[]) {
    const formData = new FormData();
    files.forEach((file: any) => {
      formData.append("logoUploaded", file);
    });

    return HttpClientService.post<any>(`/usuario/upload-logo`, formData);
  }

  getCredentials() {
    return HttpClientService.get<any>(`/usuario/credencial`);
  }
}

export default new UsuarioService();
