const hasCredencialByRoute = (permissao: any, routePermissions: string[]) => {
  const isAdminOrOwner = permissao.admin || permissao.owner;
  if (isAdminOrOwner || temPermissao(permissao.credencial, routePermissions))
    return true;

  return false;
};

function temPermissao(credencial: any, routePermissions: string[]) {
  if (routePermissions.length === 0) {
    return true;
  }

  const userPermissions = Object.entries(credencial)
    .filter(([key, value]) => value === true) // Filtra os pares onde o valor é true
    .map(([key]) => key);

  const hasPermission = routePermissions.some((permission) =>
    userPermissions.includes(permission)
  );

  return hasPermission;
}

export { hasCredencialByRoute };
