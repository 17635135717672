import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
const pinia = createPinia();
import router from './router';
import { createPinia } from 'pinia';
import { useStore } from '@/store/index';
import UsuarioService from './core/services/usuario.service';

//Interceptors
import '@/core/interceptors/auth.interceptor';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';
import '@/styles/main.scss';
import DateFnsAdapter from '@date-io/date-fns';
import enUS from 'date-fns/locale/en-US';
import ptBr from 'date-fns/locale/pt-BR';

const vuetify = createVuetify({
  components: {
    ...components,
  },
  directives,
  date: {
    adapter: DateFnsAdapter,
    locale: {
      en: enUS,
      ptBR: ptBr,
    },
  },
});

//v-mask
import { VueMaskDirective } from 'v-mask';
const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
  beforeMount: vMaskV2.bind,
  updated: vMaskV2.componentUpdated,
  unmounted: vMaskV2.unbind,
};

import { JsonEditorPlugin } from 'vue3-ts-jsoneditor';
import TipoCanal from '@/components/shared/tipo-canal/TipoCanal.vue';
import LoadingComponent from '@/components/shared/loading/LoadingComponent.vue';
import ImportacaoComponent from '@/components/shared/importacao/ImportacaoComponent.vue';

import * as Sentry from '@sentry/vue';

const app = createApp(App)
  .component('TipoCanal', TipoCanal)
  .component('Loading', LoadingComponent)
  .component('Importacao', ImportacaoComponent)
  // .component('DatePicker', DatePickerComponent)
  .use(pinia)
  .directive('mask', vMaskV3)
  .use(JsonEditorPlugin, {
    options: {},
  })
  .use(vuetify);

const store = useStore();

async function initializeApp() {
  if (!store.usuarioEstalogado) {
    app.use(router);
    app.mount('#app');
  } else {
    try {
      // Busca as credenciais do usuário
      const result = await UsuarioService.getCredentials();

      if (result.success) {
        // Armazena as credenciais no store
        store.setCredencial(result.data);
      } else {
        // Redireciona para o login caso falhe
        await router.push('/login');
      }
    } catch (error) {
      console.error('Erro ao buscar credenciais:', error);
      await router.push('/login');
    } finally {
      // Monta o app após a lógica de inicialização
      app.use(router);
      app.mount('#app');
    }
  }
}

initializeApp()
